<script>
    import MarketplaceNav from '../../Pages/marketPlace/MarketplaceNav.svelte';
    import { Link } from '@inertiajs/svelte';

    // props from marketplace controller
    export let postMarketPosts;

    import { getContext } from 'svelte';
    import axios from 'axios';
    import '../css/spinner.css';
    const marketPlaceContext = getContext('marketPlaceContext');

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');

    function submitPurchaseForm(marketId, price) {
        if (confirm(`Are you sure you want to purchase this post for ${price}?`)) {
            const postSubmitButton = document.querySelector(`#marketPostSubmitButton-${marketId}`);
            postSubmitButton.querySelector('.buttonText').style.display = 'none';
            document.querySelector(`#spinner_${marketId}`).style.display = 'inline-block';

            let values = { market_post_id: marketId };

            axios
                .post('/post-market/purchase-post', values)
                .then((response) => {
                    launchToast('success', trans('Success'), 'Congrats! Post has been purchased');

                    // remove the div from the frontend
                    const divToRemove = document.getElementById(`wrapped-div-${marketId}`);
                    divToRemove.innerHTML = '';

                    postSubmitButton.disabled = false;
                    postSubmitButton.querySelector('.spinner').style.display = 'none';
                    postSubmitButton.querySelector('.buttonText').style.display = 'inline-block';
                })
                .catch((error) => {
                    const errorMessage = error.response.data || 'An error occurred.';
                    launchToast('error', trans('Error'), errorMessage);

                    postSubmitButton.disabled = false;
                    postSubmitButton.querySelector('.spinner').style.display = 'none';
                    postSubmitButton.querySelector('.buttonText').style.display = 'inline-block';
                });
        }
    }
</script>

<svelte:component this="{MarketplaceNav}" />
<div class="col-12 min-vh-100 border-right pr-0 pt-4">
    <div class="px-3 pb-4">
        <h5 class="text-truncate mb-0 font-bold">Post Market</h5>
    </div>
    {#if postMarketPosts.length > 0}
        {#each postMarketPosts as marketPost}
            <div class="mb-4" id="{'wrapped-div-' + marketPost.id}">
                <div class="mx-auto max-w-xs">
                    <div class="flex h-full flex-col overflow-hidden rounded-lg bg-white shadow-lg">
                        <!-- Image -->
                        <a
                            class="block focus:outline-none focus-visible:ring-2"
                            href="{window.location.host + '/' + marketPost.post.user.username}"
                        >
                            <figure class="relative h-0 overflow-hidden pb-[56.25%]">
                                <img
                                    class="absolute inset-0 h-full w-full transform object-cover transition duration-700 ease-out hover:scale-105"
                                    src="{marketPost.post.user.avatar}"
                                    width="320"
                                    height="180"
                                    alt="Course"
                                />
                            </figure>
                        </a>
                        <!-- Card Content -->
                        <div class="flex flex-grow flex-col p-5">
                            <!-- Card body -->
                            <div class="flex-grow">
                                <!-- Header -->
                                <header class="text-dark mb-3">
                                    {#if marketPost.post.title}
                                        <h6><b>{marketPost.post.title.slice(0, 30)}</b></h6>
                                    {:else if marketPost.post.text}
                                        <h6><b>{marketPost.post.text.slice(0, 30)}</b></h6>
                                    {/if}

                                    <p>Impression: (from Druid)</p>
                                    Attachments : {marketPost.post.attachments_count} <br />
                                    Reactions : {marketPost.post.reactions_count} <br />
                                    Comments : {marketPost.post.comments_count} <br />
                                    Bookmark : {marketPost.post.bookmarks_count} <br />
                                    Sold count : {marketPost.post.sold_count} <br />

                                    {#if marketPost.post.supply_limit}
                                        Supply limit : {marketPost.post.supply_limit} <br />
                                    {:else}
                                        Supply limit : 0 <br />
                                    {/if}

                                    <h5><b> Price: {marketPost.listing.price}</b></h5>
                                </header>
                                <!-- Content -->
                            </div>

                            <div class="flex justify-end space-x-2">
                                <Link
                                    href="{`/${marketPost.post.user.username}`}"
                                    class="inline-flex items-center justify-center rounded border border-transparent bg-indigo-50 px-3 py-1.5 text-sm font-semibold leading-5 text-indigo-500 shadow-sm transition duration-150 ease-in-out hover:bg-indigo-100 focus:outline-none focus-visible:ring-2"
                                >
                                    View Creator Profile
                                </Link>

                                <form class="purchase-form" id="{'post-form-' + marketPost.id}">
                                    <input type="number" name="market_post_id" value="{marketPost.id}" hidden />

                                    <button
                                        class="post-buy-now-btn inline-flex items-center justify-center rounded border border-transparent bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-600 focus:outline-none focus-visible:ring-2"
                                        type="button"
                                        on:click="{() => submitPurchaseForm(marketPost.id, marketPost.listing.price)}"
                                        data-post-form-id="{marketPost.id}"
                                        id="{'marketPostSubmitButton-' + marketPost.id}"
                                    >
                                        <span id="{'spinner_' + marketPost.id}" class="spinner" style="display: none">
                                            <!-- @include('elements.icon',['icon'=>'refresh','variant'=>'small']) -->

                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                            >
                                                <path fill="none" d="M0 0h24v24H0z"></path>
                                                <path
                                                    d="M15 4v7h5V4h-5zM4 15h5v5H4v-5zM4 4v5h5V4H4zm11 11h5v5h-5v-5z"
                                                    fill="currentColor"
                                                ></path>
                                                <path d="M8 8v8h8V8H8zm7 7H9V9h6v6z" fill="currentColor"></path>
                                            </svg>
                                        </span>

                                        <span class="buttonText">Buy Now</span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/each}
    {:else}
        <h4>No Item Available</h4>
    {/if}
</div>
